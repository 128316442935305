<template>
  <div class="monitor-index">
    <div class="container-header">
      <div class="top-actions-group">
        <div class="action-left">
          <el-form class="geology-form">
            <el-select
                v-model="listfilter.dangerid"
                filterable
                remote
                reserve-keyword
                default-first-option
                clearable
                placeholder="请选择监测点"
                size="medium"
                :remote-method="getDangerList"
                :loading="getDangerLoading"
                @change="onSearch">
                <el-option
                v-for="item in dangerSelect"
                :key="item.Id"
                :label="item.DangerName"
                :value="item.Id">
                </el-option>
            </el-select>

            <el-date-picker
              v-model="filterDaterange"
              type="daterange"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              size="medium"
              style="width: 240px;margin-left: 10px;"
              value-format="yyyy-MM-dd"
              @change="dateChange"
            >
            </el-date-picker>

            <el-input
              placeholder="监测点位名称、编码"
              style="width: 300px; margin-left: 10px"
              size="medium"
              class="form-input-group"
              v-model="listfilter.fname"
              @keyup.enter.native="onSearch"
            >
              <el-button
                slot="append"
                type="primary"
                icon="el-icon-search"
                @click="onSearch"
                >查询</el-button
              >
            </el-input>
          </el-form>
        </div>
        <div class="action-right">
          <router-link to="/monitor/add">
            <el-button
              type="primary"
              icon="el-icon-circle-plus-outline"
              size="medium"
              >新增</el-button
            >
          </router-link>
        </div>
      </div>
    </div>

    <el-table
      class="list-table"
      ref="multipleTable"
      :data="listData"
      tooltip-effect="dark"
      style="width: 100%"
      :stripe="true"
      :border="false"
      v-loading="tableLoading"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" align="center">
      </el-table-column>
      <el-table-column label="监测点位名称" :show-overflow-tooltip="true" prop="MonitorName"></el-table-column>
      <el-table-column label="监测点名称" :show-overflow-tooltip="true" prop="DangerName"></el-table-column>
      <el-table-column label="隐患点类型" prop="DangerType"></el-table-column>
      <el-table-column label="运维单位" :show-overflow-tooltip="true" prop="UnitName"></el-table-column>
      <el-table-column label="隐患点等级" prop="DangerLevel"></el-table-column>
      <el-table-column label="运维人员" prop="Uname"></el-table-column>
      <el-table-column label="联系电话" prop="Mobile" width="110"></el-table-column>
      <el-table-column label="操作" width="320">
        <template slot-scope="scope">
          <el-button
            type="success"
            icon="el-icon-view"
            size="mini"
            @click="onShowDetail(scope.row)"
            >详情</el-button
          >
          <el-button type="success" icon="el-icon-position" size="mini" @click="onMapLocation(scope.row)"
            >定位</el-button
          >
          <router-link
            :to="'/monitor/edit?id=' + scope.row.Id"
            style="margin-left: 10px"
          >
            <el-button type="success" icon="el-icon-edit" size="mini"
              >修改</el-button
            >
          </router-link>
          <el-button
            type="warning"
            icon="el-icon-error"
            size="mini"
            style="margin-left: 10px"
            @click="onListDel(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      background
      layout="prev, pager, next"
      prev-text="上一页"
      next-text="下一页"
      class="list-pagination"
      :page-size="page.pagesize"
      hide-on-single-page
      :total="page.total"
      :current-page.sync="page.pageindex"
      @current-change="onPageChange"
    >
    </el-pagination>

    <el-dialog
      title="监测点位详情"
      :visible.sync="dialogDetailVisible"
      width="60%"
      custom-class="geology-dialog"
      :modal-append-to-body="true"
    >
      <monitor-detail :detailid="activeMonitorDetail.Id" :visible="dialogDetailVisible"></monitor-detail>
      <div slot="footer" class="dialog-footer">
        <router-link :to="'/monitor/edit?id='+activeMonitorDetail.Id">
          <el-button type="success" icon="el-icon-edit" size="small"
            >修 改</el-button
          >
        </router-link>
        <el-button
          size="small"
          style="margin-left: 10px"
          plain
          type="primary"
          @click="dialogDetailVisible = false"
          >关 闭</el-button
        >
      </div>
    </el-dialog>

    
    <map-location ref="mapLocation" :longitude="activeMonitorDetail.Longitude" :latitude="activeMonitorDetail.Latitude"></map-location>

  </div>
</template>
<script>
import { getDangerList, getMonitorList, delMonitor } from "@/api/base";
import monitorDetail from "./components/monitorDetail";
import mapLocation from "@/components/mapLocation";
export default {
  data() {
    return {
      listData: [],
      tableLoading: false,
      filterDaterange: "",

      getDangerLoading: false,
      dangerSelect: [],

      listfilter: {
        dangerid:'',
        fname: "",
      },
      page: {
        pagesize: 10,
        pageindex: 1,
        total: 0,
      },
      dialogDetailVisible: false,
      activeMonitorDetail:'',
    };
  },
  components: {
    monitorDetail,
    mapLocation
  },
  methods: {
    getDangerList(value) {
      this.getDangerLoading = true;
      getDangerList(1, 10, {
        fname: value,
      }).then((resdata) => {
        this.getDangerLoading = false;
        if (resdata.code == 0) {
          this.dangerSelect = resdata.data.data;
        }
      });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    dateChange(value) {
      console.log(value);
      console.log(this.filterDaterange);
      if (value) {
        var newdate = [];
        for (let index = 0; index < this.filterDaterange.length; index++) {
          const element = this.filterDaterange[index];
          newdate.push(element);
        }
        this.listfilter.fstarttime = newdate[0];
        this.listfilter.fendtime = newdate[1];
        this.getListData();
      } else {
        this.listfilter.fstarttime = "";
        this.listfilter.fendtime = "";
        this.getListData();
      }
    },
    onSearch() {
      this.page.pageindex = 1;
      this.getListData();
    },
    onPageChange(pageindex) {
      this.page.pageindex = pageindex;
      this.getListData();
    },
    getListData() {
      this.tableLoading = true;
      getMonitorList(
        this.page.pageindex,
        this.page.pagesize,
        this.listfilter
      ).then((resdata) => {
        this.tableLoading = false;
        if (resdata.code == 0) {
          this.listData = resdata.data.data;
          this.page.total = resdata.data.count * 1;
        } else {
          this.$message({
            type: "error",
            message: resdata.msg,
          });
        }
      });
    },
    onListDel(item) {
      this.$confirm("确定要删除选择的数据吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delMonitor(item.Id)
            .then((res) => {
              if (res.code == 0) {
                this.$message.success("删除成功!");
                this.getListData();
              } else {
                this.$message.error("删除失败!" + res.msg);
              }
            })
            .catch((errmsg) => {
              this.$message.error(errmsg);
            });
        })
        .catch(() => {});
    },
    onShowDetail(item){
      this.activeMonitorDetail = item;
      this.dialogDetailVisible = true;
    },
    onMapLocation(item){
      this.activeMonitorDetail = item;
      this.$refs.mapLocation.show();
    }
  },
  created() {
    this.getDangerList();
    this.getListData();
  },
};
</script>